import React from 'react'
import {graphql} from 'gatsby'
import CenteredLayout from '../components/CenteredLayout/CenteredLayout'
import SEO from '../components/SEO/SEO'
// Styles
import '../styles/app.scss'

class Agb extends React.Component {
    render() {
        const {data} = this.props
        const {markdownRemark} = data
        const {html} = markdownRemark
        return (
            <CenteredLayout location={this.props.location}>
                <SEO title="AGB"/>
                <div dangerouslySetInnerHTML={{__html: html}}/>
                <h3>Download der AGBs</h3>
                <ul>
                    <li>
                        <a href="/downloads/agb/MunichSafetyAGB_DE.pdf"
                           download="Munich Safety AGB DE.pdf">Munich Safety AGB DE.pdf</a>
                    </li>
                    <li>
                        <a href="/downloads/agb/MunichSafetyAGB_EN.pdf"
                           download="Munich Safety AGB EN.pdf">Munich Safety AGB EN.pdf</a>
                    </li>
                </ul>
            </CenteredLayout>
        )
    }
}

export default Agb

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/agb.md/" }) {
      html
    }
  }
`
